import 'antd/es/notification/style/css';
import { startCase } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compose } from 'react-apollo';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';
import ModalSuccess from '../../../components/modals/modal-success';
import withApollo from '../../../config/with-apollo';
import { AppContext, PageContext } from '../../../helpers/context';
import Card from './card';
import SubscriptionDetails from './details';

const UserSubscriptionPage = (props) => {
   const { user_current_plan } = useContext(AppContext);
   const { setBookNow, setRequest } = useContext(PageContext);

   const [showSuccessModal, setShowSuccessModal] = useState(false);
   const [subInterval, setSubInterval] = useState('yearly');

   useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const status = urlParams.get('status');
      if (status === 'success') {
         ReactGA.event({
            category: 'General',
            action: `User upgraded to ${startCase(user_current_plan.subscription_type.toLowerCase())} subscription`,
            nonInteraction: true,
         });

         setShowSuccessModal(true);
      }
   }, []);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   const redirectToPage = () => {
      const url = localStorage.getItem('@return-url');
      if (url) {
         localStorage.removeItem('@return-url');
         props.history.replace(url);
      }
   };

   const modalProps = useMemo(() => {
      const url = localStorage.getItem('@return-url');
      if (!url) return {};
      return {
         redirect: redirectToPage,
      };
   }, []);

   return (
      <PageContext.Provider
         value={{
            subInterval,
            setSubInterval,
            showSuccessModal,
            setShowSuccessModal,
         }}>
         <div className={'min-h-screen bg-light-gray pb-10'}>
            <div className={'container mx-auto pt-10'}>
               <ModalSuccess {...modalProps} title={'Subscription successful'} subtitle={`You have successfully subscribed to our ${user_current_plan.subscription_type ? startCase(user_current_plan.subscription_type.toLowerCase()) : ''} subscription`} />

               <div className='bg-white p-2 md:p-2 block md:flex justify-center'>
                  <Card subscription={user_current_plan.subscriptionPropertyBySubscriptionProperty} />
               </div>

               <div className='bg-white p-1 md:p-4 md:flex justify-center text-center'>
                  <span className='text-lg font-bold'>All Subscriptions</span>
               </div>

               <div className='bg-white p-1 md:p-4 md:flex justify-center'>
                  <div class='elfsight-app-7a8caa16-8ccc-43f0-86f3-350e9c4f92d8'></div>
               </div>

               <div class='zigzag1'></div>
               <div class='zigzag2'></div>

               <div className='bg-white pt-7 md:flex justify-center text-center'>
                  <span className='text-3xl font-bold'>FAQ</span>
               </div>

               <div className='bg-white p-4 pt-0 block md:flex justify-center'>
                  <SubscriptionDetails />
               </div>
            </div>
         </div>
      </PageContext.Provider>
   );
};

export default compose(
   withApollo,
   withRouter
)(UserSubscriptionPage);
